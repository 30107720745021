import { Route } from "react-router-dom";
import React, { Suspense, lazy, FC, useMemo } from "react";
import Loading from "../../utils/Loading";
import { connect } from "react-redux";
import SignIn from "../../Pages/SignIn";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { isNil } from "lodash";
import wowzaRecordings from "api/wowzaRecordings";

const Domains = lazy(() => import("../../Pages/Domains"));
const ControlRoom = lazy(() => import("../../Pages/ControlRoom"));
const Tools = lazy(() => import("../../Pages/Tools"));
const Content = lazy(() => import("../../Pages/Content"));
const Series = lazy(() => import("../../Pages/Series"));
const Logs = lazy(() => import("../../Pages/Logs"));
const Users = lazy(() => import("../../Pages/Users"));
const Transcoders = lazy(() => import("../../Pages/Transcoders"));
const StreamTargets = lazy(() => import("../../Pages/StreamTargets"));
const Account = lazy(() => import("../../Pages/Account"));
const Venues = lazy(() => import("../../Pages/Venues"));
const Home = lazy(() => import("../../Pages/Home"));
const Passes = lazy(() => import("../../Pages/Passes"));
const FullAccess = lazy(() => import("../../Pages/FullAccess"));
const Coupons = lazy(() => import("../../Pages/Coupons"));
const Payments = lazy(() => import("../../Pages/Payments"));
const Marketing = lazy(() => import("../../Pages/Marketing"));
const Campaigns = lazy(() => import("../../Pages/Campaigns"));
const Balance = lazy(() => import("../../Pages/Balance"));
const Sales = lazy(() => import("../../Pages/Sales"));
const Customers = lazy(() => import("../../Pages/Customers"));
const Bookmarks = lazy(() => import("../../Pages/Bookmarks"));
const Platform = lazy(() => import("../../Pages/Platform"));
const Announcements = lazy(() => import("../../Pages/Annoucements"));
const Recordings = lazy(() => import("../../Pages/Recordings"));
const Submissions = lazy(() => import("../../Pages/Submissions"));
const Guides = lazy(() => import("../../Pages/Guides"));
const Tags = lazy(() => import("../../Pages/Tags"));
const WowzaRecordings = lazy(() => import("../../Pages/WowzaRecordings"));
const AdminRecordigns = lazy(() => import("../../Pages/Admin/Recordings"));
const AdminAppTest = lazy(() => import("Pages/Admin/AppTest"));

type TAppMain = {
  currentAccount: any;
  isSignedIn: boolean;
  user: any;
}

const AppMain: FC<TAppMain> = ({ user, currentAccount, isSignedIn }) => { 
  if (!isSignedIn) {
    return <SignIn />;
  }

  if (isNil(currentAccount)) {
    return <Loading />; 
  }

  const isAdmin: boolean = user.roles.includes('admin');

  return (<>
      <Suspense fallback={<Loading />}>
        <Route exact path="/" component={Home} />
        {/* PRODUCTION */}
        <Route path="/control-room" component={(props) => <ControlRoom {...props} />} />
        <Route path="/tools" component={Tools} />
        {/* CONTENT */}
        <Route path="/content" component={(props) => <Content {...props} />} />
        <Route path="/shows" component={Series} />
        <Route path="/bookmarks" component={Bookmarks} />
        <Route path="/passes" component={Passes} />
        <Route path="/tags" component={Tags} />
        {/* MARKETING */}
        <Route path="/campaigns" component={Campaigns} />
        <Route path="/marketing" component={Marketing} />
        {/* FINANCIAL */}
        <Route path="/sales" component={Sales} />
        <Route path="/payments" component={Payments} />
        <Route path="/balance" component={Balance} />
        <Route path="/coupons" component={Coupons} />
        <Route path="/full-access" component={FullAccess} />
        <Route path="/customers" component={Customers} />
        {/* CONFIGURATION */}
        <Route path="/domains" component={Domains} />
        <Route path="/transcoders" component={Transcoders} />
	      <Route path="/stream_targets" component={StreamTargets} />
        <Route path="/users" component={Users} />
        <Route path="/logs" component={Logs} />
        <Route path="/venues" component={Venues} />
        {/* PLATFORM */}
        {isAdmin && (<>
          <Route path="/announcements" component={Announcements} />
          <Route path="/platform" component={Platform} />
          <Route path={["/guide","/guides"]} component={Guides} />
          <Route path="/submissions" component={Submissions} />
          <Route path="/wowza-recordings" component={WowzaRecordings} />
          <Route path="/admin/recordings" component={AdminRecordigns} />
          <Route path="/admin/apps" component={AdminAppTest} />
        </>)}
        {/* OTHERS */}
        <Route path="/recordings" component={Recordings} />
        <Route path="/account" component={Account} />
      </Suspense>
      <ToastContainer autoClose={2000} />
  </>);
};

const mapStateToProps = (state) => {
  const { currentAccount, currentUser, accounts, isSignedIn } = state.auth;
  const index = accounts.findIndex((a) => a.id === currentAccount);
  return {
    currentAccount: index === -1 ? accounts[0] : accounts[index],
    isSignedIn,
    user: currentUser?.data
  };
};

export default connect(mapStateToProps)(AppMain);
